























import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';

import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { App } from "@/store/app";
@Component({
    components: {},
    computed: {}
})

export default class Profile extends Vue {
    formPassword: any = {}
 
    response: boolean = false
    async created() {
        await Core.switchLoad(true)
        this.response = true;
        await Core.switchLoad(false)
    } 

    async changePassword(event: any) {
        let user = await User.getUser()
        if (this.formPassword.password == this.formPassword.password2) {
            let change = await Core.putHttp(`/api/user/password/${user.pk}/`, this.formPassword)
            this.formPassword = {}
            if (change.id) { 
                await App.success("เปลี่ยนรหัสผ่านสำเร็จ")
            }
        } else {
            await App.error("รหัสผ่านไม่ตรงกัน")
            // await App.error("รหัสผ่านไม่ตรงกัน")
        }
    }
}
