






























import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core"; 
import { App } from "@/store/app";
import {
    City
} from "@/store/city";
import moment from "moment";
@Component({
    components: {},
    computed: {}
})

export default class Profile extends Vue {
    response: boolean = false
    form: any = {};
    profile: any = {}
    update: boolean = true;
    async created() {
        await this.loadChoices();
        await this.load();

    }
    async load() {
        await Auth.checkToken();
        if (Auth.logined) {
            await Core.switchLoad(true)
            this.form = await Auth.getUser()
            this.profile = await Core.getHttp(`/api/default/profile/?user=${this.form.pk}`)
            if (this.profile[0]) {
                this.profile = this.profile[0]
                await this.setCity()
            } else {
                this.profile = {}
                this.update = false;
            }
            await Core.switchLoad(false)
            this.response = true;
        }
    }

    choices: any = {}
    async loadChoices() {
        this.choices = {
            "prefix": {
                th:['นาย','นาง','นางสาว'],
                en:['Mr.','Mrs.','Miss'],
                ch:['先生','女士','小姐']
            },
        }
    }

    async saveUser() {
        this.profile.geo = City.currentGeo ?.id
        this.profile.province = City.currentProvince ?.id
        this.profile.amphur = City.currentAmphur ?.id
        this.profile.district = City.currentDistrict ?.id
        if (this.update) {
            await Core.putHttp(`/api/auth/user/`, this.form)
            await Core.putHttp(`/api/default/profile/${this.profile.id}/`, this.profile)
            await this.load();
            await App.success("บันทึกข้อมูลสำเร็จ")
        } else {
            console.log(this.profile)
            this.profile.user = this.form.pk
            await Core.putHttp(`/api/auth/user/`, this.form)
            await Core.postHttp(`/api/default/profile/`, this.profile)
            await this.load();
            await App.success("บันทึกข้อมูลสำเร็จ")
        }

    }

    async openCityDialog() {
        City.dialogCityState = true
    }

    get CityFrom() {
        return City.showName
    }

    async setCity() {
        City.currentGeo = await Core.getHttp(`/api/default/geography/${this.profile.geo}/`)
        City.currentProvince = await Core.getHttp(`/api/default/province/${this.profile.province}/`)
        City.currentAmphur = await Core.getHttp(`/api/default/amphur/${this.profile.amphur}/`)
        City.currentDistrict = await Core.getHttp(`/api/default/district/${this.profile.district}/`)
        await City.setShowName()
    }

    @Watch('profile.birthday')
    async onChangeProvince(val: string) {
        this.profile.age = moment().diff(val, 'years', false);

    }
}
