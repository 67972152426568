


























import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { App } from "@/store/app";
@Component({
    components: {},
    computed: {}
})

export default class Personal extends Vue {
    profile: any = null
    profileImage: any = null;
    response: boolean = false
    async created() {
        // await Core.switchLoad(true)
        await this.loadProfile()
        // await Core.switchLoad(false)
        this.response = true;
    }
    async loadProfile() {
        this.profile = await User.getProfile()
        this.profileImage = await Core.getHttp(`/api/user/personal/image/${this.profile.id}/`)
    }
    async updatePersonal() {
        let update = await Core.putHttp(`/api/default/profile/${this.profile.id}/`, this.profile)
        if (update.id) {
            await App.success("บันทึกข้อมูลสำเร็จ")
            await this.loadProfile() //.$_lang('บันทึกข้อมูลสำเร็จ','Successfully saved data','成功保存数据')
        }

    }

    async personalImageChange(event: any) {
        let file = await this.getBase64(event.target.files[0])
        let profileImage: any = this.$refs.profileImage
        profileImage.src = file
        this.profileImage.presonal_image = file
        let data = await Core.putHttp(`/api/user/personal/image/${this.profile.id}/`, this.profileImage)
        if (data.id) {
             this.profileImage.presonal_image = data.presonal_image
            await App.success("บันทึกข้อมูลสำเร็จ")
        }
    }

    async getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

}
