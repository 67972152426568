











































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import MapView from '@/components/core/Map.vue';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { App } from "@/store/app";
import {
    City
} from "@/store/city";
@Component({
    components: { MapView },
    computed: {},
})

export default class Saller extends Vue {
    form: any = {}
    response: boolean = false;
    user: any = null
    profile: any = null
    saller: boolean = false;
    currentLocation: any = {}

    async created() {
        // await Core.switchLoad(true)
        await this.loadFarm();
        // await Core.switchLoad(false)

    }

    async loadFarm() {
        this.user = await Auth.getUser()
        this.profile = await User.getProfileFull();
        this.form = await Core.getHttp(`/api/user/farm/${this.user.pk}/`)
        if (this.form.id) {
            this.saller = true;
            await this.generateMap()
        }
        this.response = true;
    }

    async generateMap() {
        this.response = false;
        let map = (this.form.location).split(',')
        if (map.length > 1) {
            this.currentLocation = {
                center: { 'Latitude': parseFloat(map[0]), 'Longitude': parseFloat(map[1]) },
                mark: [{ 'Latitude': parseFloat(map[0]), 'Longitude': parseFloat(map[1]) }, ]
            }
        }
    }

    async createShop() {
        if (this.profile != null) {
            let shop = await Core.postHttp(`/api/default/farm/`, {
                'user': this.user.pk,
                location: '19.0284279,99.8940557',
            })

            if (shop.id) {
                await App.success("สร้างร้านค้าของคุณสำเร็จแล้ว")
                await this.loadFarm();
            }
        } else {
            await App.error("กรุณาระบุข้อมูลส่วนตัวให้ครบถ้วนก่อน") 
            this.$emit('changePage',1)
        }

    }
    get latCore() {
        return Map.lat
    }
    get lngCore() {
        return Map.lng
    }

    @Watch('latCore')
    async changeMapCore() {
        this.form.latitude = Map.lat
        this.form.longitude = Map.lng
        this.form.location = `${Map.lat},${Map.lng}`
        await this.update();
    }

    async update() {
        this.response = false
        this.form.geo = City.currentGeo ?.id
        this.form.province = City.currentProvince ?.id
        this.form.amphur = City.currentAmphur ?.id
        this.form.district = City.currentDistrict ?.id
        let farm = await Core.putHttp(`/api/default/farm/${this.form.id}/`, this.form)
        if (farm.id) {
            await App.success("บันทึกข้อมูลสำเร็จ") 
        } else { 
            await App.error("เกิดข้อผิดพลาดในการแก้ไขข้อมูล")
        }
        await this.loadFarm();
    }

    async openCityDialog() {
        City.dialogCityState = true
    }

    get CityFrom() {
        return City.showName
    }

    async setCity() {
        City.currentGeo = await Core.getHttp(`/api/default/geography/${this.form.geo}/`)
        City.currentProvince = await Core.getHttp(`/api/default/province/${this.form.province}/`)
        City.currentAmphur = await Core.getHttp(`/api/default/amphur/${this.form.amphur}/`)
        City.currentDistrict = await Core.getHttp(`/api/default/district/${this.form.district}/`)
        await City.setShowName()
    }
    async gotoMyShop(){
        if(this.form.name && this.form.farm_address){
            this.$router.push('/user/myshop')
        }else{
            // alert('กรุณาระบุข้อมูลให้ครบถ้วน')
            await App.error("กรุณาระบุข้อมูลร้านค้าให้ครบถ้วน")
        }
    }
}
